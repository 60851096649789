body {
  padding: 20px;
  /* background-color: rgba(65, 65, 65, 0.192); */
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div.tooltip {	
  position: absolute;	
  text-align: center;	
  padding: 2px;
  font: 12px 'Montserrat';
  background: rgba(255, 255, 255, 0.9);
  border: 0px;
  border-radius: 8px;	
  pointer-events: none;	
}